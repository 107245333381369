<template>
    <q-dialog v-model="dialogVisibles" persistent @show='downshow' @hide='downhide'>
        <!-- 打包中显示  -->
        <q-card style="min-width: 350px" v-if='!downDatas.reportStatus'>
            <q-toolbar>
                <q-toolbar-title> 
                    <span class="text-weight-bold">等待对账单生成</span>
                </q-toolbar-title>

                <q-btn flat round dense icon="close" v-close-popup />
            </q-toolbar>

            <q-card-section>
                <div class='cubebox'>
                    <q-spinner-cube
                        color="primary"
                        size="5.5em"
                    />
                </div>
            </q-card-section>
            <q-card-section>
                <div class='downTips'>
                    <q-icon name="iconfont icon-jinggao" color="primary" />
                    报表生成中请勿关闭此窗口
                </div>
            </q-card-section>
        </q-card>

      <!-- 打包完成的页面 -->
      <q-card style="min-width: 350px" v-if='downDatas.reportStatus'>
        <q-toolbar>
            <q-toolbar-title> 
                <span class="text-weight-bold">生成对账单</span>
            </q-toolbar-title>

            <q-btn flat round dense icon="close" v-close-popup />
        </q-toolbar>

        <q-card-section>
            <div class='downicon'>
                <q-icon name="iconfont icon-xiazai" color="primary" size="56px" />
            </div>
        </q-card-section>

        <q-card-section>
            <div class='downbox'>
                <q-btn class="downbtn" color="primary" label="导出" @click='downzip'/>
                <q-btn class="downbtn" color="white" text-color="black" label="取消" @click='downhide'/>
            </div>
        </q-card-section>
        <!-- <q-card-section>
            <div class='downTips'>
                <q-icon name="iconfont icon-jinggao" color="primary" />
                当前报表已生成,关闭窗口将需要重新生成报表
            </div>
        </q-card-section> -->
      </q-card>
    </q-dialog>
</template>
<script>

import { reportList,reportExport } from '@/api/OrderReport.js'

export default {
    props:{
        dialogVisible:{
            type: Boolean,
            required: true
        },
        // 检查详细或是序列详细需要
        downData:{
            type: Object,
            // required: true
        },
        // idtype: seriesId或者studyId  检查id还是序列id
        queryData:{
            type: Object,
            required: true
        },
    },
    data() {
        return {

        }
    },
    created(){
    },
    computed:{
        dialogVisibles:{
            get(){
                return this.dialogVisible
            },
            set(v){
                this.$emit('update:dialogVisible',v)
            }
        },
        downDatas:{ //查看时所需信息
            get(){
                return this.downData
            },
            set(v){
                this.$emit('update:downData',v)
            }
        },
        queryDatas:{ //查看时所需信息
            get(){
                return this.queryData
            },
            set(v){
                this.$emit('update:queryData',v)
            }
        },
    },
    methods:{
        async downshow(){
            // this.downData.reportStatus = true

            const url= window.origin + 
            "/order/report/export?beginDate=" +
            encodeURIComponent(this.queryDatas.beginDate) +
            "&endDate=" +
            encodeURIComponent(this.queryDatas.endDate);

            this.fileconversion(url)

            this.dialogVisibles = false
        },
        // 二进制文件转换
        fileconversion(url){
            // let arry
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'arraybuffer';
            let token = sessionStorage.getItem('token')
            xhr.setRequestHeader("token", token)

            console.log(xhr);
            xhr.onreadystatechange = function () {
                if (this.readyState === 4 && this.status === 200) {
                    const data = xhr.response;
                    var filename =''
                    var disposition = xhr.getResponseHeader('Content-Disposition');
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) { 
                        filename = matches[1].replace(/['"]/g, '');
                        }
                    }
                    // console.log(data);
                    // console.log(disposition);
                    // console.log(matches);
                    // console.log(filename);
                    try {
                        // this.downloadFile(data)
                        const blob = new Blob([data], {
                            type: "application/arraybuffer",
                        });
                        // 兼容不同浏览器的URL对象
                        const wurl = window.URL || window.webkitURL || window.moxURL;
                        // 创建下载链接
                        const downloadHref = wurl.createObjectURL(blob);
                        // 创建a标签并为其添加属性
                        const link = document.createElement("a");
                        link.href = downloadHref;
                        link.download = filename;
                        // 触发点击事件执行下载
                        document.body.appendChild(link);
                        link.click();
                        // 下载完成就删掉a标签
                        document.body.removeChild(link); 
                        //释放blob对象
                        wurl.revokeObjectURL(downloadHref);

                        // arry = data
                    } catch (err) {
                        console.error(err);
                    }

                }
            };

           
            xhr.send();
            // return arry
            // $('#image').attr("src", )
        },

        //弹窗关闭时
        downhide(){
            this.dialogVisibles = false
            this.downData.reportStatus = false
            this.downData.downloadUrl = ''
            this.downData.fileName = ''
            // this.jinduintervalEnd()
        },
        //普通影像资料检查进度去定时器
        jinduintervalEnd(){
            if (this.downTimeInterval) {
                this.downTimelist.forEach((item, index) => {
                    window.clearInterval(item);
                })
                this.downTimelist = [];
                this.downTimeInterval = null;
            }
        },
        
        //下载影像资料
        async downzip(){
            const url= window.origin + 
            "/order/report/export?beginDate=" +
            encodeURIComponent(this.queryDatas.beginDate) +
            "&endDate=" +
            encodeURIComponent(this.queryDatas.endDate);

            this.fileconversion(url)
        }
    },
}
</script>
<style lang="less" scoped>
.downicon{
    text-align: center;
}
.downbox{
    text-align: center;
    .downbtn{
        margin: 0 15px;
    }
}
.downTips{
    text-align: center;
}
.cubebox{
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
