<template>
    <div id="report" class="q-pa-md q-gutter-sm">
        <!-- 面包屑 -->
        <q-breadcrumbs>
            <q-breadcrumbs-el icon="iconfont icon-zhuye" label="主页" to="/orm/dashboard" />
            <q-breadcrumbs-el label="订单管理" icon="iconfont icon-dingdanguanli" />
            <q-breadcrumbs-el label="导出报表" icon="iconfont icon-baobiao" to="/order/report/list" />
        </q-breadcrumbs>
        <!-- 内容区 -->
        <div class='reportBox' id='reportBox'>
            <!-- 搜索区 -->
            <q-card class="my-card" style="margin-bottom: 10px;">
                <q-card-section>
                    <div class=" searchbox" id="searchbox">
                        <div class="search">
                            <div class="doctorNameBox">
                                <el-input v-model="queryData.doctorName" placeholder="医生姓名" clearable 
                                    @clear='change' @blur='change'
                                    :disabled='Permissionslist.filter(item=>item==="order.report.view").length>0?false:true'
                                >
                                </el-input>
                            </div>
                            <div class="orderStatusBox">
                                <el-select v-model="queryData.orderStatus" clearable placeholder="请选择订单状态" @change='change'
                                    :disabled='Permissionslist.filter(item=>item==="order.report.view").length>0?false:true'
                                >
                                    <el-option
                                    v-for="item in orderStatus"
                                    :key="item.key"
                                    :label="item.value"
                                    :value="item.key"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="startendBox">
                                <el-date-picker
                                    value-format='yyyy-MM-dd'
                                    style="width: 100%;"
                                    v-model="startendTime.beginEndDate"
                                    type="daterange"
                                    range-separator=":"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    @change='dateChange'
                                    :picker-options="pickerOptions"
                                    unlink-panels
                                    :disabled='Permissionslist.filter(item=>item==="order.report.view").length>0?false:true'
                                >
                                </el-date-picker>
                            </div>
                        </div>

                        <div class="addbtn">
                            
                            <morefields
                                ref='morefields'
                                :checkList.sync='checkList'
                            />
                            <!-- 更多筛选 -->
                            <morePopover 
                                :form.sync='queryData'
                                :startendTime.sync='startendTime'
                                :china='china'
                                @getreportList='getreportList'
                                @reportForm='reportForm'
                            />

                            <el-button class="reportbtn" round size="mini" type="primary" plain icon="el-icon-download" @click='reportForm'
                                :disabled='Permissionslist.filter(item=>item==="order.report.export").length>0?false:true'
                            >
                                生成对账单
                            </el-button>

                        </div>
                    </div>
                </q-card-section>
            </q-card>
            <!-- 列表区域 -->
            <!-- 
                v-loading="ordervisible"
                element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(55,55,55,0.2)"
                :max-height="tableHeight"
                @sort-change="sortthiscolumn"
             -->
            <q-card class="my-card">
                <q-card-section>
                    <el-table
                        ref="multipleTable"
                        border
                        stripe
                        :data="reportAllList"
                        tooltip-effect="dark"
                        style="width: 100%"
                        v-loading="ordervisible"
                        element-loading-text="拼命加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(55,55,55,0.2)"
                        @sort-change="sortthiscolumn"
                        :max-height="tableHeight"
                    >
                        <template slot="empty">
                            {{nodatetype}}
                        </template>
                        <!-- 患者姓名 -->
                        <el-table-column v-if='checkList.patientName' show-overflow-tooltip sortable="custom" align='center' prop="patientName" label="患者姓名" min-width="104px"></el-table-column>
                        <!-- 医生 -->
                        <el-table-column v-if='checkList.doctorName' show-overflow-tooltip sortable="custom" align='center' prop="doctorName" label="医生"></el-table-column>
                        <!-- 省份 -->
                        <el-table-column v-if='checkList.provinceName' show-overflow-tooltip sortable="custom" align='center' prop="provinceName" label="省份"></el-table-column>
                        <!-- 城市 -->
                        <el-table-column v-if='checkList.cityName' show-overflow-tooltip sortable="custom" align='center' prop="cityName" label="城市"></el-table-column>
                        <!-- 医院 -->
                        <el-table-column v-if='checkList.hospName' show-overflow-tooltip sortable="custom" align='center' prop="hospName" label="医院"></el-table-column>
                        <!-- 科室 -->
                        <el-table-column v-if='checkList.departName' show-overflow-tooltip sortable="custom" align='center' prop="departName" label="科室"></el-table-column>
                        <!-- 套餐 -->
                        <el-table-column v-if='checkList.comboName' show-overflow-tooltip sortable="custom" align='center' prop="comboName" label="套餐"></el-table-column>
                        <!-- 入账方 -->
                        <el-table-column v-if='checkList.merchantName' show-overflow-tooltip sortable="custom" align='center' prop="merchantName" label="入账方" min-width="94px"></el-table-column>
                        <!-- 订单日期 -->
                        <el-table-column v-if='checkList.createTime' show-overflow-tooltip sortable="custom" align='center' prop="createTime" label="订单日期" min-width="156px"></el-table-column>
                        <!-- 订单金额 -->
                        <el-table-column v-if='checkList.orderAmt' show-overflow-tooltip sortable="custom" align='center' prop="orderAmt" label="订单金额" min-width="104px"></el-table-column>
                        <!-- 回款金额 -->
                        <el-table-column v-if='checkList.orderReturnedAmt' show-overflow-tooltip sortable="custom" align='center' prop="orderReturnedAmt" label="回款金额" min-width="104px"></el-table-column>
                        <!-- 加急金额 -->
                        <el-table-column v-if='checkList.urgentAmt' show-overflow-tooltip sortable="custom" align='center' prop="urgentAmt" label="加急金额" min-width="104px"></el-table-column>
                        <!-- 折扣码 -->
                        <el-table-column v-if='checkList.discountCode' show-overflow-tooltip sortable="custom" align='center' prop="discountCode" label="折扣码" min-width="94px"></el-table-column>
                        <!-- 折扣 -->
                        <el-table-column v-if='checkList.discountPercent' show-overflow-tooltip sortable="custom" align='center' prop="discountPercent" label="折扣"></el-table-column>
                        <!-- 重建人员 -->
                        <el-table-column v-if='checkList.reconUser' show-overflow-tooltip sortable="custom" align='center' prop="reconUser" label="重建人员" min-width="104px"></el-table-column>
                        <!-- 订单审核人员 -->
                        <el-table-column v-if='checkList.orderCheckUser' show-overflow-tooltip sortable="custom" align='center' prop="orderCheckUser" label="订单审核人员" min-width="130px"></el-table-column>
                        <!-- 资料审核人员 -->
                        <el-table-column v-if='checkList.informationCheckUser' show-overflow-tooltip sortable="custom" align='center' prop="informationCheckUser" label="资料审核人员" min-width="130px"></el-table-column>

                        <!-- 是否加急 -->
                        <el-table-column v-if='checkList.urgentStatus' show-overflow-tooltip sortable="custom" align='center' prop="urgentStatus" label="是否加急" min-width="125px">
                            <template slot-scope="{row}">
                                <!-- {{row.orderUrgent}} -->
                                <q-chip v-if="row.urgentStatus" square text-color="white" :color="orderUrgentcolor[row.urgentStatus]">
                                    <!-- {{ (orderUrgent.filter(item=>item.key == row.urgentStatus).pop() || {} ).value }} -->
                                    {{  row.urgentStatus?'是':'否' }}
                                </q-chip>
                            </template>
                        </el-table-column>
                        <!-- 订单时段 -->
                        <el-table-column v-if='checkList.weekDay' show-overflow-tooltip sortable="custom" align='center' prop="weekDay" label="订单时段" min-width="125px">
                            <template slot-scope="{row}">
                                <!-- {{row.orderUrgent}} -->
                                <q-chip square text-color="white" :color="weekDaycolor[row.weekDay]">
                                    <!-- {{ (orderUrgent.filter(item=>item.key == row.urgentStatus).pop() || {} ).value }} -->
                                    {{  row.weekDay?'周末':'工作日' }}
                                </q-chip>
                            </template>
                        </el-table-column>
                        <!-- 订单类型 -->
                        <el-table-column v-if='checkList.orderType' show-overflow-tooltip sortable="custom" align='center' prop="orderType" label="订单类型" min-width="104px">
                            <template slot-scope="scope">
                                <q-chip v-show="scope.row.orderType!=null" square :color="orderTypecolor[scope.row.orderType]" text-color="white">
                                    {{ (orderType.filter(item=>item.key == scope.row.orderType).pop() || {} ).value }}
                                </q-chip>
                            </template>
                        </el-table-column>
                        <!-- 订单状态 -->
                        <el-table-column v-if='checkList.orderStatus' sortable="custom" align='center' prop="orderStatus" label="订单状态" min-width="125px" >
                            <template slot-scope="scope">
                                <q-chip square :color="orderStatuscolor[scope.row.orderStatus]" text-color="white">
                                {{ (orderStatus.filter(item=>item.key == scope.row.orderStatus).pop() || {} ).value }}
                                </q-chip>
                            </template>
                        </el-table-column>
                        <!-- 支付状态 -->
                        <el-table-column v-if='checkList.payStatus' sortable="custom" align='center' prop="isPay" label="支付状态" min-width="125px">
                            <template slot-scope="payStatus">
                                <q-chip square :color="payStatus.row.payStatus ? 'positive': 'primary'" text-color="white">
                                {{ payStatus.row.payStatus ? '已支付':'待支付' }}
                                </q-chip>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页组件 -->
                    <elementPagination class="Paginationstyle" :total='queryData.total' :queryData='queryData' @getList='getreconList()' @changepageSize='changepageSize' @changePage='changePage'/>
                </q-card-section>
            </q-card>
        </div>
        <!-- 导出报表弹窗 -->
        <downDialog
            ref="downDialog"
            :dialogVisible.sync='downData.down'
            :queryData.sync='queryData'
            :downData.sync='downData'
        />
    </div>
</template>
<script>
import { getHeight } from '@/api/getHeight.js'; // 获取列表最高高度

import elementPagination from '@/components/ElementUI/elementPagination.vue'; // 分页组件
import morePopover from '@/components/OrderReport/morePopoverAndFields/morePopover.vue' //更多筛选
import Morefields from '@/components/OrderReport/morePopoverAndFields/Morefields.vue' //更多字段
import downDialog from '@/components/OrderReport/down/downDialog.vue' //更多字段


import { getprovince } from '@/api/components/checkcitys.js'; // 获取城市接口
import { reportList,reportExport } from '@/api/OrderReport.js'
import { makeDebounce } from "@/utils/debounce.js";  
import { mapState } from 'vuex'

export default {
    data() {
        return {
            nodatetype:'',
            ordervisible: false,//控制列表加载画面
            tableHeight:500, //列表最大高度
            // 分页设置
            queryData:{ // element分页信息
                page: 1,
                pageSize: 10,
                total:0,
                sortType:'', //排序类型
                sortField:'', //顺序字段
                doctorName:'', //医生姓名
                beginDate:'', //订单开始日期
                endDate:'', //订单结束日期
                provinceId:'', //省份id
                cityId:'', //城市id
                hospId:'', //医院id
                departId:'', //科室id
                orderStatus:'', //订单状态
            },
            startendTime:{
                beginEndDate:'', //订单开始和结束日期
            },
            // 导出报表时所需资料
            downData:{
                down:false,
                reportStatus: false, //控制出现等待页面还是导出页面
                downloadUrl:'', //下载地址
                fileName:'', //下载地址
            },
            china:{
                provinces:[],
                cities:[],
                hosps:[],
                departs:[],
            },
            reportAllList:[], //所有节点

            checkList: {
                patientName: true, //患者姓名
                doctorName: true, //医生
                provinceName: false, //省份
                cityName: false, //城市
                hospName: true, //医院
                departName: false, //科室
                comboName: false, //套餐
                merchantName: false, //入账方
                createTime: true,  //订单日期
                orderAmt: true, //订单金额
                orderReturnedAmt: true, //回款金额
                discountCode: false, //折扣码
                discountPercent: false, //折扣
                urgentAmt: false, //加急金额
                reconUser: true, //重建人员
                orderCheckUser: true,//审核人员
                informationCheckUser: false, //资料审核人员
                urgentStatus: false, //是否加急
                weekDay: false,//订单时段
                orderType: false, //订单类型
                orderStatus: false, //订单状态
                payStatus: false, //支付状态
            },
            pickerOptions: {
                shortcuts: [ {
                    text: '今天',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '最近1个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            // 订单类型颜色
            orderTypecolor:{
                0: 'positive',
                1: 'primary',
            },
            // 加急状态颜色
            orderUrgentcolor:{
                'false': 'positive',
                'true': 'primary',
            },
            // 订单时段颜色
            weekDaycolor:{
                'false': 'positive',
                'true': 'primary',
            },
            // 订单状态颜色
            orderStatuscolor:{
                0: 'warning',
                1: 'primary',
                2: 'primary',
                3: 'negative',
                4: 'primary',
                5: 'positive',
            },
            
        }
    },
    created(){
        this.getreportList()
        this.getprovincelist()
    },
    mounted() {
        
    },
    computed:{
        ...mapState([
            'discountStatus', //优惠码使用状态
            'orderUrgent',//加急状态
            'orderStatus', //订单状态
            'orderType',//订单类型
            'payStatus', // 支付状态
            'isPay', //代理商付款状态
            'Permissionslist' // 权限词典
        ])
    },
    components:{
        elementPagination,
        morePopover,
        Morefields,
        downDialog,
    },
    methods:{
        // 获取省份
        async getprovincelist(){
            this.china.provinces = await getprovince()
        },
        refresh:makeDebounce(function(evt){
            let target = evt.target;
            if(target.nodeName == "SPAN"){
                target = evt.target.parentNode;
            }
            target.blur()
            this.getreportList()
        },300),

        //获取报表列表
        getreportList(){
            this.nodatetype=''
            this.ordervisible = true
            setTimeout(async() =>{
                const { data:res } = await reportList({
                    ...this.queryData,
                })
                console.log('报表列表',res);
                if(res.code===20000){
                    this.reportAllList = res.data.orders
                    this.queryData.total=res.data.totals*1

        
                    let height = document.getElementById('searchbox').offsetHeight + 80
                    this.tableHeight = getHeight('reportBox',height)

                    this.ordervisible = false
                    this.nodatetype='暂无数据'
                }else{
                    this.ordervisible = false
                    this.nodatetype='数据获取失败'
                }
            },1000)
        },
        // 搜索条件改变时触发
        change(){
            this.queryData.page = 1
            this.getreportList()
        },
        //排序
        sortthiscolumn(column){
            if(column.order=='ascending'){
        
                if(column.prop=='provinceName'){
                    this.queryData.sortField = 'provinceId'
                    this.queryData.sortType = 1
                }else if(column.prop=='cityName'){
                    this.queryData.sortField = 'cityId'
                    this.queryData.sortType = 1
                }else if(column.prop=='hospName'){
                    this.queryData.sortField = 'hospId'
                    this.queryData.sortType = 1
                }else if(column.prop=='comboName'){
                    this.queryData.sortField = 'comboId'
                    this.queryData.sortType = 1
                }else if(column.prop=='departName'){
                    this.queryData.sortField = 'departId'
                    this.queryData.sortType = 1
                }else{
                // ascending 为升序
                    this.queryData.sortField = column.prop
                    this.queryData.sortType = 1
                }

            }else if(column.order=='descending'){

                if(column.prop=='provinceName'){
                    this.queryData.sortField = 'provinceId'
                    this.queryData.sortType = 0
                }else if(column.prop=='cityName'){
                    this.queryData.sortField = 'provinceId'
                    this.queryData.sortType = 0
                }else if(column.prop=='hospName'){
                    this.queryData.sortField = 'provinceId'
                    this.queryData.sortType = 0
                }else if(column.prop=='comboName'){
                    this.queryData.sortField = 'provinceId'
                    this.queryData.sortType = 0
                }else if(column.prop=='departName'){
                    this.queryData.sortField = 'provinceId'
                    this.queryData.sortType = 0
                }else{
                    // ascending 为降序
                    this.queryData.sortField = column.prop
                    this.queryData.sortType = 0
                }

            }else{
                // 默认按照时间排序排序
                this.queryData.sortField = 'createTime'
                this.queryData.sortType = 0
            }
            this.getreportList()
        },
        // 搜索框改变时
        dateChange(v){
            this.queryData.page = 1
            this.queryData.beginDate = this.startendTime.beginEndDate ?this.startendTime.beginEndDate[0] :''
            this.queryData.endDate = this.startendTime.beginEndDate?this.startendTime.beginEndDate[1] :''
            this.getreportList()
        },
        //报表导出
        async reportForm(){
            this.$q.dialog({
                title: '生成对账单',
                message: `你确定要根据筛选中的条件来生成对账单吗?`,
                ok: { label: '确定'},
                cancel: { label: '取消',flat: true},
            }).onOk(() => {
                // this.$emit('reportForm')   
                //打开报表导出弹框
                this.downData.down = true
            })
            
        },

    
 
        
        //出生日期转换
        expireDateZH(expireDate){
            if(expireDate){
                let arr = expireDate.split('');
                arr.splice(4,0,'-');
                arr.splice(7,0,'-');
                var Date = arr.join('')
                return Date
            }else{
                return ''
            }
        },
        // 分页发送变化函数
        changepageSize(val){
            this.queryData.pageSize = val
            this.getreportList()
        },
        changePage(val){
            this.queryData.page = val
            this.getreportList()
        },
    },
}
</script>
<style lang="less" scoped>
#report{
  height: 100%;
}
.reportBox{
  height: 90%;
}
.searchbox{
    display: flex;
    justify-content: space-between;
    .search{
        display: flex;
        flex-direction: row ;
        //align-items: flex-end;
        align-items: center;
        flex-wrap: wrap;
        .doctorNameBox{
            width: 150px;
            margin: 0 10px;
        }
        .startendBox{
            width: 250px;
            margin: 0 10px;
        }
        .orderStatusBox{
            width: 150px;
            margin: 0 10px;
        }
        .operation{
            // margin: .625rem .625rem 0 .625rem;
            margin: 0 .625rem 0 .625rem;
            width: 12.5rem;
        }
    }
}

.addbtn{
    display: flex;
    flex-direction: row ;
    justify-content: flex-end;
    align-items: center;
    //align-items: flex-end;
    flex-wrap: wrap;
    .el-button--primary {
        color: #FFF;
        background-color: #409EFF  ;
        border-color: #409EFF  ;
    }
    .reportbtn{
        margin: 0 15px;
    }
}
.czbtn{
  margin-right: .75rem;
}
.newCaozuobox{
  // display: flex;
  // justify-content: space-between;
  .czTit{
    float: left;
    text-align: right;
    width:50%;
  }
  .czpopoverbtn{
    float: left;text-align: right;width:50%;
  }
}

//popover 中字体颜色
.popoverColor{
    color: white;
    font-weight: bold;
}
//运行状态样式
.q-badge{
    padding: 10px;
}
//分页器样式
.Paginationstyle{
  margin-top: .9375rem;
}

</style>
