<template>
    <div>
        <el-popover
            placement="bottom"
            width="500"
            trigger="click"
            popper-class='morePopover'
        >
            <el-form ref="form" :model="form" label-width="90px">
                <div style="display: flex;flex-direction: row;justify-content: space-between;" class='moreBox'>
                    <!-- 更多筛选下拉框左 -->
                    <div class="moreleft">
                        <!-- 医生姓名 -->
                        <el-form-item label="医生姓名">
                            <el-input v-model="forms.doctorName"></el-input>
                        </el-form-item>
                        <!-- 城市 -->
                        <el-form-item label="城市">
                            <el-select v-model="forms.cityId" clearable placeholder="请选择" @change='cityChange'>
                                <el-option
                                v-for="item in chinas.cities"
                                :key="item.id"
                                :label="item.cityName"
                                :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <!-- 科室 -->
                        <el-form-item label="科室">
                            <el-select v-model="forms.departId" clearable placeholder="请选择">
                                <el-option
                                v-for="item in chinas.departs"
                                :key="item.id"
                                :label="item.departName"
                                :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <!-- 更多筛选下拉框右 -->
                    <div class="moreright">
                        <!-- 省份 -->
                        <el-form-item label="省份">
                            <el-select v-model="forms.provinceId" clearable placeholder="请选择" @change='provinceChange'>
                                <el-option
                                v-for="item in chinas.provinces"
                                :key="item.id"
                                :label="item.provinceName"
                                :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <!-- 医院 -->
                        <el-form-item label="医院">
                            <el-select v-model="forms.hospId" clearable placeholder="请选择" @change='hospChange'>
                                <el-option
                                v-for="item in chinas.hosps"
                                :key="item.id"
                                :label="item.hospName"
                                :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <!-- 订单状态 -->
                        <el-form-item label="订单状态">
                            <el-select v-model="forms.orderStatus" clearable placeholder="请选择">
                                <el-option
                                v-for="item in orderStatus"
                                :key="item.key"
                                :label="item.value"
                                :value="item.key">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div style="width: 100%;">
                    <!-- 检查日期 -->
                    <el-form-item label="订单日期">
                        <el-date-picker
                            value-format='yyyy-MM-dd'
                            style="width: 100%;"
                            v-model="startendTimes.beginEndDate"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions"
                            unlink-panels
                        >
                        </el-date-picker>
                    </el-form-item>
                </div>
                <div style="text-align: center;">
                    <el-button round size="medium" icon="el-icon-search" @click='search'
                        :disabled='Permissionslist.filter(item=>item==="order.report.view").length>0?false:true'
                    >
                        搜索
                    </el-button>
                    <el-button round size="medium" icon="el-icon-refresh-right" @click='Reset'
                        :disabled='Permissionslist.filter(item=>item==="order.report.view").length>0?false:true'
                    >
                        重置
                    </el-button>
                    <!-- <el-button round size="medium" icon="el-icon-download" @click='report'>导出</el-button> -->
                </div>
            </el-form>
                
            <el-button style='margin-left: 10px;' slot="reference" round size="medium" icon="el-icon-caret-bottom">更多筛选</el-button>
        </el-popover>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { makeDebounce } from "@/utils/debounce.js";  
import { getprovince,getcity,gethosplist,getdepart,getoptions } from '@/api/components/checkcitys.js'; // 获取城市接口

export default {
    props: {
        form:{
            type: Object,
            required: true
        },
        startendTime:{
            type: Object,
            required: true
        },
        // 包含所有的 省城医科
        china:{
            type: Object,
            required: true
        }
    },
    data() {
        return {
            pickerOptions: {
                shortcuts: [ {
                    text: '今天',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '最近1个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
        }
    },
    created(){
    },
    computed:{
        ...mapState([
            'orderStatus', //订单状态
            'Permissionslist' // 权限词典
        ]),
        forms:{
            get(){
                return this.form
            },
            set(v){
                this.$emit('update:form',v)
            }
        },
        startendTimes:{
            get(){
                return this.startendTime
            },
            set(v){
                this.$emit('update:startendTime',v)
            }
        },
        // 包含所有的 省城医科
        chinas:{
            get(){
                return this.china
            },
            set(v){
                this.$emit('update:china',v)
            }
        }
    },
    methods:{
        // 省份改变
        async provinceChange(){
            this.chinas.cities = [] //清空城市
            this.chinas.hosps = [] //清空医院
            this.chinas.departs = [] //清空科室

            this.forms.cityId = '' //清空城市id
            this.forms.hospId = '' //清空医院id
            this.forms.departId = '' //清空科室id
            // 如果有省份id
            if(this.forms.provinceId){
                this.chinas.cities = await getcity(this.forms.provinceId)
            }
        },
        // 城市改变
        async cityChange(){
            this.chinas.hosps = [] //清空医院
            this.chinas.departs = [] //清空科室

            this.forms.hospId = '' //清空医院id
            this.forms.departId = '' //清空科室id
            // 如果有医院id
            if(this.forms.cityId){
                this.chinas.hosps = await gethosplist(this.forms.cityId)
            }
        },
        // 医院改变
        async hospChange(){
            this.chinas.departs = [] //清空科室

            this.forms.departId = '' //清空科室id
            // 如果有医院id
            if(this.forms.hospId){
                this.chinas.departs = await getdepart(this.forms.hospId)
            }
        },
        //搜索
        search:makeDebounce(function(evt){
            let target = evt.target;
            if(target.nodeName == "SPAN"){
                target = evt.target.parentNode;
            }
            target.blur()
            this.forms.beginDate = this.startendTimes.beginEndDate ?this.startendTimes.beginEndDate[0] :''
            this.forms.endDate = this.startendTimes.beginEndDate?this.startendTimes.beginEndDate[1] :''
            this.forms.page = 1
            this.$emit('getreportList')
        },300),
        //重置
        Reset(evt){
            let target = evt.target;
            if(target.nodeName == "SPAN"){
                target = evt.target.parentNode;
            }
            target.blur()
            this.forms.page = 1
            this.forms.beginDate = ''
            this.forms.endDate = ''
            this.forms.departId = ''
            this.forms.hospId = ''
            this.forms.cityId = ''
            this.forms.provinceId = ''
            this.forms.doctorName = ''
            this.startendTimes.beginEndDate = ''
            this.forms.orderStatus = ''
            this.$emit('getreportList')
        },
        // 报表导出
        report(){
            this.$q.dialog({
                title: '报表导出',
                message: `你确定要根据以上条件来导出报表吗?`,
                ok: { label: '确定'},
                cancel: { label: '取消',flat: true},
            }).onOk(() => {
                this.$emit('reportForm')   
            })
        }
    },
}
</script>
<style lang="less" scoped>
//更多筛选下拉框设置
.moreBox{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .moreleft{
        font-size: 20px;
    }
}
</style>
