<template>
    <el-dropdown :hide-on-click="false">
        <span class="el-dropdown-link">
            更多字段<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown" style="max-height: 300px;overflow: auto;">
            <!-- 患者姓名 -->
            <el-dropdown-item>
                <el-checkbox v-model="checkLists.patientName" label="患者姓名"></el-checkbox>
            </el-dropdown-item>
            <!-- 医生 -->
            <el-dropdown-item>
                <el-checkbox v-model="checkLists.doctorName" label="医生"></el-checkbox>
            </el-dropdown-item>
            <!-- 省份 -->
            <el-dropdown-item>
                <el-checkbox v-model="checkLists.provinceName" label="省份"></el-checkbox>
            </el-dropdown-item>
            <!-- 城市 -->
            <el-dropdown-item>
                <el-checkbox v-model="checkLists.cityName" label="城市"></el-checkbox>
            </el-dropdown-item>
            <!-- 医院 -->
            <el-dropdown-item>
                <el-checkbox v-model="checkLists.hospName" label="医院"></el-checkbox>
            </el-dropdown-item>
            <!-- 科室 -->
            <el-dropdown-item>
                <el-checkbox v-model="checkLists.departName" label="科室"></el-checkbox>
            </el-dropdown-item>
            <!-- 套餐 -->
            <el-dropdown-item>
                <el-checkbox v-model="checkLists.comboName" label="套餐"></el-checkbox>
            </el-dropdown-item>
            <!-- 入账方 -->
            <el-dropdown-item>
                <el-checkbox v-model="checkLists.merchantName" label="入账方"></el-checkbox>
            </el-dropdown-item>
            <!-- 订单日期 -->
            <el-dropdown-item>
                <el-checkbox v-model="checkLists.createTime" label="订单日期"></el-checkbox>
            </el-dropdown-item>
            <!-- 订单金额 -->
            <el-dropdown-item>
                <el-checkbox v-model="checkLists.orderAmt" label="订单金额"></el-checkbox>
            </el-dropdown-item>
            <!-- 回款金额 -->
            <el-dropdown-item>
                <el-checkbox v-model="checkLists.orderReturnedAmt" label="回款金额"></el-checkbox>
            </el-dropdown-item>
            <!-- 加急金额 -->
            <el-dropdown-item>
                <el-checkbox v-model="checkLists.urgentAmt" label="加急金额"></el-checkbox>
            </el-dropdown-item>
            <!-- 折扣码 -->
            <el-dropdown-item>
                <el-checkbox v-model="checkLists.discountCode" label="折扣码"></el-checkbox>
            </el-dropdown-item>
            <!-- 折扣力度 -->
            <el-dropdown-item>
                <el-checkbox v-model="checkLists.discountPercent" label="折扣"></el-checkbox>
            </el-dropdown-item>
            <!-- 重建人员 -->
            <el-dropdown-item>
                <el-checkbox v-model="checkLists.reconUser" label="重建人员"></el-checkbox>
            </el-dropdown-item>
            <!-- 订单审核人员 -->
            <el-dropdown-item>
                <el-checkbox v-model="checkLists.orderCheckUser" label="订单审核人员"></el-checkbox>
            </el-dropdown-item>
            <!-- 资料审核人员 -->
            <el-dropdown-item>
                <el-checkbox v-model="checkLists.informationCheckUser" label="资料审核人员"></el-checkbox>
            </el-dropdown-item>
            <!-- 是否加急 -->
            <el-dropdown-item>
                <el-checkbox v-model="checkLists.urgentStatus" label="是否加急"></el-checkbox>
            </el-dropdown-item>
            <!-- 订单时段 -->
            <el-dropdown-item>
                <el-checkbox v-model="checkLists.weekDay" label="订单时段"></el-checkbox>
            </el-dropdown-item>
            <!-- 订单金额 -->
            <el-dropdown-item>
                <el-checkbox v-model="checkLists.orderType" label="订单类型"></el-checkbox>
            </el-dropdown-item>
            <!-- 订单状态 -->
            <el-dropdown-item>
                <el-checkbox v-model="checkLists.orderStatus" label="订单状态"></el-checkbox>
            </el-dropdown-item>
            <!-- 支付状态 -->
            <el-dropdown-item>
                <el-checkbox v-model="checkLists.payStatus" label="支付状态"></el-checkbox>
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>
<script>
export default {
    props:{
        checkList:{
            type: Object,
            required: true
        }
    },
    data() {
        return {
        }
    },
    created(){
    },
    computed:{
        checkLists:{
            get(){
                return this.checkList
            },
            set(v){
                this.$emit('update:checkList',v)
            }
        }
    },
    methods:{
    },
}
</script>
<style lang="less" scoped>

</style>
